<template>
    <div class="ny_contactUs">
      <div class="ny_contactUs_bg">
        <img :src="lxData.lx_bg" alt="" style="width:100%" />
      </div>
      <div class="ny_contactUs_nav">
        <div
          class="nav_col"
          v-html="lxData.lx_content"
        ></div>
      </div>
    </div>
  </template>
  
  <script>
  import { getPageMsg } from '@/api/api'
  import onLoading from '@/common/Loading/onLoading.vue'
  export default {
    components: {
      onLoading
    },
    data () {
      return {
        lxData:{}
      }
    },
    watch: {},
    methods: {
      getData () {
        let that = this
        let id = sessionStorage.getItem(that.changeData() + 'id')
        getPageMsg(id).then(res => {
          if (res.data.code == 1) {
            that.lxData = res.data.data.lxwm
          }
        })
      }
    },
    mounted () {
      this.getData()
    }
  }
  </script>
  <style lang="less" scoped>
  @import url('~@/assets/css/mixins.less'); //混合
  .ny_contactUs {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f1f1f1;
    .ny_contactUs_bg {
      width: 100%;
      overflow: hidden;
      position: relative;
    }
    .ny_contactUs_nav {
      margin: 1rem auto;
      width: 1400px;
      .nav_col {
        line-height: 2;
        font-size: 14px;
        text-align: center;
        /deep/div {
          max-width: 100%;
        }
        /deep/h4 {
          font-weight: 600;
          width: 100%;
          span {
            font-size: 30px;
          }
        }
      }
    }
  }
  </style>
  